import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import Router from "./Router";
import Config from "./Config/Packages.config";
import "../node_modules/onemin-ui/dist/Scss/Fonts.css";
import "./Index.css";

const defaultLang: string | undefined = Cookies.get("lang");
Config(defaultLang);

const AppRoot = (
    <BrowserRouter>
        <Router />
    </BrowserRouter>
);

ReactDOM.render(AppRoot, document.getElementById("root"));
