import RestaurantBlock from "../../RestaurantBlock/View/RestaurantBlock.view";
import K_Expressz from "../../../Resources/kiraly_alacart.png";
import K_Menu from "../../../Resources/kiraly_menu.png";
export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    return (
        <div className="main-page">
            {/* <header className="main-page-header">
                <HeaderBlock></HeaderBlock>
            </header> */}
            <footer className="main-page-footer">
                <RestaurantBlock
                    backgournd_url={K_Expressz}
                    link="https://www.expresszpizza.com"
                    className="align-right"
                />

                <RestaurantBlock
                    backgournd_url={K_Menu}
                    link="https://menu.kiralyexpressz.com"
                    className="align-left"
                />

                {/* <RestaurantBlock
                    name="Ünnepi Hidegtálrendelés"
                    address=""
                    phone="06 30 325 3256"
                    link="https://karacsony.mealbox.hu/"
                /> */}
            </footer>
        </div>
    );
};

export default MainPageView;
